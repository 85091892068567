
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $('.btn-scroll-top').on('click', function(event) {
            event.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 600); 
        });
        $('.contact-open').on('click', function(event) {
            event.preventDefault();
            if($(this).hasClass('open')){
                $(this).removeClass('open');
                $('.form-contact').animate({opacity: 0}, 300);
                $('.form-contact').hide('slow');
            }else{
                $(this).addClass('open');
                $('.form-contact').show('slow');
                $('.form-contact').animate({opacity: 1}, 300);
            }
        });
        $('.btn-close-contact').on('click', function(event) {
            event.preventDefault();
            $('.contact-open').click();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        setTimeout(function() {
            $('#home-carousel-0').carousel({
                pause: "hover",
                interval: 5000
            });
            $('#home-carousel-1').carousel({
                pause: "hover",
                interval: 6000 
            });
            $('#home-carousel-2').carousel({
                pause: "hover",
                interval: 7000 
            });
        }, 500);
        var monitor = setInterval(function(){
            var elem = document.activeElement;
            if(elem && elem.tagName === 'IFRAME'){                
                $('#home-carousel-1').carousel('pause');
                clearInterval(monitor);
            }
        }, 100);
      },
      finalize: function() {  
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_chambres': {
      init: function() {
        // JavaScript to be fired on the about us page
        setTimeout(function() {
            $('.carousel').carousel({
                pause: "hover"
            });
        }, 500);
      }
    },
    'page_template_template_infos': {
        init: function() {
            function templateInfoWindow() {
              var title = $('#map').data('title');
              var street = $('#map').data('adress');
              var zipcode = $('#map').data('zipcode');
              var city = $('#map').data('city');
              var content = "";
              content += '<div class="infowindow-wrapper">';
                if (typeof title !== 'undefined' && title) { content += '<h3 class="title">'+title+'</h3>'; }
                content += '<div class="address">';
                  if (typeof street !== 'undefined' && street) { content += '<div class="street">'+street+'</div>'; }
                  if (typeof zipcode !== 'undefined' && zipcode) { content += '<span class="zipcode">'+zipcode+'</span>'; }
                  if (typeof city !== 'undefined' && city) { content += '<span class="city"> '+city+'</span>'; }
                content += '</div>';
                
              content += '</div>';
              return content;
            }
            function bindInfoWindow(marker, map, infowindow) {
              google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(templateInfoWindow());
                infowindow.open(map, marker);
              });
            }
            function initMap() {
              var lat = $('#map').data('lat');
              var lng = $('#map').data('lng');
              mapCanvas = document.getElementById('map');
              mapOptions = {
                center: {lat: lat, lng: lng},
                zoom: 12,
                scrollwheel: false,
              };

              map = new google.maps.Map(mapCanvas, mapOptions);
              infowindow = new google.maps.InfoWindow({backgroundColor: 'rgba(255,255,255,0.5)'});

              marker1 = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: map,
                icon : $('#map').data('marker')
              });
              bindInfoWindow(marker1, map, infowindow);     

              google.maps.event.trigger(marker1, 'click');
            }
            // setTimeout(function() {
            //     initMap(); 
            // }, 200);
            

            
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
